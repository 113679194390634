import PageLayout from "../../components/MDComponents/LayoutContainers/PageLayout";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBase/MDBox";
import MDButton from "../../components/MDBase/MDButton";
import MDInput from "../../components/MDBase/MDInput";
import React, { useCallback, useEffect, useState } from "react";
import MDTypography from "../../components/MDBase/MDTypography";
import { addOrReplaceItem, getAllItems } from "util/indexedDBService";
import MDAvatar from "../../components/MDBase/MDAvatar";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {v4 as uuidv4} from "uuid";

import miningDark from "assets/images/mining-dark.png";
import miningLight from "assets/images/mining-light.png";
import farmDark from "assets/images/farm-dark.png";
import farmLight from "assets/images/farm-light.png";
import smeltingDark from "assets/images/smelting-dark.png";
import smeltingLight from "assets/images/smelting-light.png";
import shippingDark from "assets/images/shipping-dark.png";
import shippingLight from "assets/images/shipping-light.png";
import disenchantingDark from "assets/images/disenchanting-dark.png";
import disenchantingLight from "assets/images/disenchanting-light.png";
import mappingDark from "assets/images/mapping-dark.png";
import mappingLight from "assets/images/mapping-light.png";
import image from "assets/images/boatleague.jpeg";
import WorkerCard from "components/Cards/WorkerCard";
import { debounce } from "lodash";
import IdleCard from "../../components/Cards/IdleCard";
import Card from "@mui/material/Card";

const jobData = [
  { id: 0, name: "Mining", light: miningLight, dark: miningDark },
  { id: 1, name: "Farm", light: farmLight, dark: farmDark },
  { id: 2, name: "Smelting", light: smeltingLight, dark: smeltingDark },
  { id: 3, name: "Shipping", light: shippingLight, dark: shippingDark },
  { id: 4, name: "Disenchanting", light: disenchantingLight, dark: disenchantingDark },
  { id: 5, name: "Mapping", light: mappingLight, dark: mappingDark },
];

function Home() {
  const [selectedJob, setSelectedJob] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [note, setNote] = useState("");
  const [gold, setGold] = useState("1");
  const [jobStates, setJobStates] = useState(
    jobData.reduce((acc, { id }) => {
      acc[id] = { slots: 1, roster: [], minimumLevel: 0 };
      return acc;
    }, {})
  );
  const [idle, setIdle] = useState({ roster: [] });
  const [initialized, setInitialized] = useState(false);

  const [preview, setPreview] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const handleNoteChange = (event) => setNote(event.target.value);
  const handleGoldChange = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setGold(numericValue);
  };

  const getData = async () => {
    const base = await getAllItems();
    const updatedJobStates = { ...jobStates };
    let updatedIdleStates = { ...idle};
    base.forEach((item) => {
      const { id } = item;
      if (id !== undefined) {
        if (id === 99) {
          updatedIdleStates = item;
        } else if (updatedJobStates[id]){
          updatedJobStates[id] = item;
        }
      }
    });
    setJobStates(updatedJobStates);
    setIdle(updatedIdleStates);
    handleJob(0);
    setInitialized(true);
  };

  const handleJob = (job) => {
    setSelectedJob(job);
    // setSelectedLevel(jobStates[job].minimumLevel || 1);
  };

  const handleLevel = (level) => setSelectedLevel(level);

  const handleLevelVariant = (level) => {
    const { minimumLevel } = jobStates[selectedJob];
    // if (minimumLevel > level) return "text";
    if (selectedLevel === level) return "contained";
    return "outlined";
  };

  const handleAdd = async () => {
    const updatedIdle = {
      roster: [...idle.roster, { id: uuidv4(), note: note, level: selectedLevel, job:selectedJob, price: (!isNaN(parseInt(gold)))?parseInt(gold):1 }],
    };
    await handleIdleMarking(updatedIdle);
  };

  const debouncePreview = useCallback(
    debounce(() => {
      handlePreview()
    }, 300),
    [selectedJob, selectedLevel, note, gold]
  );

  useEffect(() => {
    if (initialized) {
      debouncePreview()
    }
  }, [debouncePreview]);

  const popperProps = {
    modifiers: [{ name: "offset", options: { offset: [0, 20] } }],
  };

  const renderJobButton = ({ id, name, light, dark }) => (
    <Tooltip key={id} title={name} PopperProps={popperProps}>
      <MDButton
        size="medium"
        variant="text"
        onClick={() => handleJob(id)}
        iconOnly
        sx={{ opacity: selectedJob === id ? 1 : 0.4 }}
      >
        <MDAvatar src={selectedJob === id ? light : dark} size="xl" />
      </MDButton>
    </Tooltip>
  );

  const handleSlots = async (id, amount) => {
    const updatedJob = {
      ...jobStates[id],
      slots: jobStates[id].slots + amount,
    };
    const updatedJobState = structuredClone(jobStates);
    updatedJobState[id] = updatedJob

    setJobStates((prevState) => ({ ...prevState, [id]: updatedJob }));
    await addOrReplaceItem(id, updatedJob);
    await handleIdleMarking(null, updatedJobState)
  }

  const getJobPrice = (id) => {
    return jobStates[id].roster.reduce((sum, obj) => sum + obj.price, 0);
  }

  const getTotalPrice = () => {
    return (
      jobStates[0].roster.reduce((sum, obj) => sum + obj.price, 0) +
      jobStates[1].roster.reduce((sum, obj) => sum + obj.price, 0) +
      jobStates[2].roster.reduce((sum, obj) => sum + obj.price, 0) +
      jobStates[3].roster.reduce((sum, obj) => sum + obj.price, 0) +
      jobStates[4].roster.reduce((sum, obj) => sum + obj.price, 0) +
      jobStates[5].roster.reduce((sum, obj) => sum + obj.price, 0)
    );
  }

  const renderJobRoster = ({ id, light, dark }) => (
    <Grid item xs={4} xl={2} key={id}>
      <MDBox display="flex" alignItems="center" justifyContent="center" sx={{flexDirection: "column", gap:"0.5rem"}}>
        <MDAvatar src={selectedJob === id ? light : dark} size="xl" sx={{ opacity: selectedJob === id ? 1 : 0.6 }} />
        <MDTypography variant="caption">
          {`${getJobPrice(id)} /hr (${((getJobPrice(id)/getTotalPrice())*100).toFixed(0)}%)`}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="center" my={1} sx={{ gap: "1rem" }}>
        <MDButton
          size="small"
          disabled={jobStates[id].slots === 1 || jobStates[id].slots <= jobStates[id].roster.length}
          variant="outlined"
          iconOnly
          onClick={() => {handleSlots(id, -1)}}
        >
          <Icon fontSize="large">remove</Icon>
        </MDButton>
        <MDTypography variant="body2">
          {`${jobStates[id].roster.length} / ${jobStates[id].slots}`}
        </MDTypography>
        <MDButton size="small" variant="outlined" iconOnly onClick={() => {handleSlots(id, 1)}}>
          <Icon fontSize="large">add</Icon>
        </MDButton>
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="center" >
        <Grid container spacing={1}>
          {
            (jobStates[id].roster.length===0)?(
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100px"
                border="1px dashed"
                borderColor="#63656C"
                borderRadius="8px"
                width="100%"
                mt={1}
              >
                <MDTypography variant="body2">
                  No workers
                </MDTypography>
              </MDBox>
            ):(
              jobStates[id].roster.map((item, index) => (
                <Grid key={uuidv4()} item xs={12}>
                  <WorkerCard
                    item={item}
                    deleteCallback={(newItem)=>{
                      handleDelete(id, newItem);
                    }}
                    updateCallback={(newItem)=>{
                      handleUpdate(id, newItem);
                    }}/>
                </Grid>
              )
            ))}
        </Grid>
      </MDBox>
    </Grid>
  );

  const handleUpdate = async (id, newItem) => {
    const roster = jobStates[id].roster;
    const index = roster.findIndex(obj => obj.id === newItem.id);
    if (index !== -1) {
      roster[index] = newItem;
    }

    // todo revise minimum level
    const minimumLevel = (jobStates[id].slots <= roster.length) ? Math.min(...roster.map(obj => obj.level)) : 0;

    const updatedJob = {
      ...jobStates[id],
      roster: roster,
      minimumLevel: minimumLevel
    };

    const updatedJobState = structuredClone(jobStates);
    updatedJobState[id] = updatedJob;

    setJobStates((prevState) => ({ ...prevState, [id]: updatedJob }));
    await addOrReplaceItem(id, updatedJob);
    await handleIdleMarking(structuredClone(idle), updatedJobState);
  }

  const handleDelete = async (id, newItem) => {
    const roster = jobStates[id].roster.filter(obj => obj.id !== newItem.id);
    // todo revise minimum level
    const minimumLevel = (jobStates[id].slots <= roster.length) ? Math.min(...roster.map(obj => obj.level)) : 0;

    const updatedJob = {
      ...jobStates[id],
      roster: roster,
      minimumLevel: minimumLevel
    };

    const updatedJobState = structuredClone(jobStates);
    updatedJobState[id] = updatedJob;

    setJobStates((prevState) => ({ ...prevState, [id]: updatedJob }));
    await addOrReplaceItem(id, updatedJob);
    await handleIdleMarking(null, updatedJobState);
  }

  const handleIdleMarking = async (newIdle = null, updatedJobState = null) => {
    const newJobStates = structuredClone((updatedJobState??jobStates));

    (newIdle??idle).roster.map((item) => {
      if (newJobStates[item.job].roster.length < newJobStates[item.job].slots) {
        newJobStates[item.job].roster.push(item);
      } else {
        const minimumLevel = Math.min(...newJobStates[item.job].roster.map(obj => obj.level));
        if (minimumLevel < item.level) {
          const index = newJobStates[item.job].roster.findIndex(obj => obj.level === minimumLevel);
          if (index !== -1) {
            newJobStates[item.job].roster[index] = item
          }
        } else if (minimumLevel === item.level) {
          const filter = newJobStates[item.job].roster.filter(obj => obj.level === item.level);
          const maxPrice = Math.max(...filter.map(obj => obj.price));
          if (maxPrice > item.price) {
            const index = newJobStates[item.job].roster.findIndex(obj => obj.price === maxPrice);
            if (index !== -1) {
              newJobStates[item.job].roster[index] = item
            }
          }
        }
      }
    });

    const newIdleState = structuredClone(newIdle??idle);

    newIdleState.roster.map((item) => {
      const index = newJobStates[item.job].roster.findIndex(obj => obj.id === item.id);
      if (index !== -1) {
        if (index >= (updatedJobState??jobStates)[item.job].roster.length) {
          // mark as add new
          item.idleTag = 'NEW';
        } else {
          // mark as swap with this item
          item.idleTag = (updatedJobState??jobStates)[item.job].roster[index].id
        }
      } else {
        // mark as disregard
        item.idleTag = 'disregard'
      }
    })

    setIdle(newIdleState);
    await addOrReplaceItem(99, newIdleState);
  }

  const handlePreview = () => {
    const list = [...jobStates[selectedJob].roster];

    (idle.roster.filter(obj => obj.job === selectedJob)).map((item)=>{
      if (item.idleTag === 'NEW') {
        list.push(item);
      } else if (item.idleTag !== 'disregard' && item.idleTag) {
        const index = list.findIndex(obj => obj.id === item.idleTag);
        list[index] = item;
      }
    });

    const previewItem = {
      level: selectedLevel,
      job: selectedJob,
      price: (!isNaN(parseInt(gold)))?parseInt(gold):1
    }

    if (list.length<jobStates[selectedJob].slots) {
      setPreview('ADD');
    } else {
      const sortedLevels = list.map(obj => obj.level).sort((a, b) => b - a);
      const topLevels = sortedLevels.slice(0, jobStates[selectedJob].slots);

      const minimumLevel = Math.min(...topLevels);

      if (minimumLevel < previewItem.level) {
        setPreview('ADD'); // swap level
      } else if (minimumLevel === previewItem.level) {
        const filter = list.filter(obj => obj.level === previewItem.level);
        const maxPrice = Math.max(...filter.map(obj => obj.price));

        if (maxPrice > previewItem.price) {
          const index = list.findIndex(obj => obj.price === maxPrice);
          if (index !== -1) {
            setPreview('ADD'); // swap price
          }
        } else {
          setPreview("NO")
        }
      } else {
        setPreview("NO")
      }
    }
  }

  const handleAddToRoster = async (item) => {
    const {idleTag, ...rest} = item;
    const newRoster = [...jobStates[item.job].roster, { ...rest }]

    // todo revise minimum level
    const minimumLevel = (jobStates[item.job].slots <= newRoster.length) ? Math.min(...newRoster.map(obj => obj.level)) : 0;


    const updatedJob = {
      ...jobStates[item.job],
      roster: newRoster,
      minimumLevel: minimumLevel
    };
    const updatedJobStates = structuredClone(jobStates)
    updatedJobStates[item.job] = updatedJob

    setJobStates((prevState) => ({ ...prevState, [item.job]: updatedJob }));
    await addOrReplaceItem(item.job, updatedJob);

    const updatedIdle = {
      roster: idle.roster.filter(obj => obj.id !== item.id)
    }
    await handleIdleMarking(updatedIdle, updatedJobStates);
  };

  const handleSwap = async (idleItem, rosterItem) => {
    const idleIndex = idle.roster.findIndex(obj => obj.id === idleItem.id);
    const rosterIndex = jobStates[rosterItem.job].roster.findIndex(obj => obj.id === rosterItem.id);

    const { idleTag, ...cleanedIdleItem } = idleItem;
    const { idleTag: idleTag2, ...cleanedRosterItem } = rosterItem;

    const updatedIdle = structuredClone(idle);
    updatedIdle.roster[idleIndex] = { ...cleanedRosterItem }

    const roster = jobStates[idleItem.job].roster;
    roster[rosterIndex] = { ...cleanedIdleItem }

    // todo revise minimum level
    const minimumLevel = (jobStates[idleItem.job].slots <= roster.length) ? Math.min(...roster.map(obj => obj.level)) : 0;

    const updatedJob = {
      ...jobStates[idleItem.job],
      roster: roster,
      minimumLevel: minimumLevel
    };

    const updatedJobState = structuredClone(jobStates);
    updatedJobState[idleItem.job] = updatedJob;

    setJobStates((prevState) => ({ ...prevState, [idleItem.job]: updatedJob }));

    await addOrReplaceItem(idleItem.job, updatedJob);
    await handleIdleMarking(updatedIdle, updatedJobState);
  }

  const handleUpdateIdle = async (newIdleItem) => {
    const newIdle = structuredClone(idle);
    const index = idle.roster.findIndex(obj => obj.id === newIdleItem.id);
    if (index !== -1) {
      newIdle.roster[index] = newIdleItem;
      await handleIdleMarking(newIdle);
    }
  }

  const handlePreviewColor = () => {
    if (preview === "NO") {
      return "error"
    } else if (preview === "ADD") {
      return "success"
    } else {
      return undefined
    }
  }

  const handleDeleteIdle = async (idleItem) => {
    const roster = idle.roster.filter(obj => obj.id !== idleItem.id);

    const updatedIdle = {
      roster: roster
    };
    await handleIdleMarking(updatedIdle);
  }

  return (
    <PageLayout >
      <Grid container spacing={2} sx={{paddingX:"1rem", paddingBottom: "1rem"}}>
        <Grid item xs={12}>
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <MDBox
              width="calc(50% - 2rem)"
              mx={2}
              my={2}
              py={6}
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox display="flex" alignItems="center" justifyContent="center" my={3} sx={{ gap: "3rem" }}>
            {jobData.map(renderJobButton)}
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox display="flex" alignItems="center" justifyContent="center" mt={1} sx={{ gap: "0.5rem" }}>
            <MDTypography>Level</MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" justifyContent="center" sx={{ gap: "0.5rem" }}>
            {[...Array(10)].map((_, i) => (
              <MDButton
                key={i + 1}
                variant={handleLevelVariant(i + 1)}
                iconOnly
                onClick={() => handleLevel(i + 1)}
              >
                {i + 1}
              </MDButton>
            ))}
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox display="flex" alignItems="center" justifyContent="center" mt={1} sx={{ gap: "1rem" }}>
            <MDInput label="Note" onChange={handleNoteChange} />
            <MDInput
              variant="outlined"
              type="number"
              label="Gold"
              defaultValue={1}
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: (
                  <MDTypography variant="body2" color="white">
                    /hr
                  </MDTypography>
                ),
              }}
              onChange={handleGoldChange}
            />
            <MDButton onClick={handleAdd}>ADD</MDButton>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox
            variant={initialized?"gradient":null}
            bgColor={initialized?handlePreviewColor():null}
            borderRadius="lg"
            coloredShadow={initialized?handlePreviewColor():null}
            p={2}
            textAlign="center"
            sx={{opacity:(preview)?"0.8":"0"}}
          >
            <MDTypography>
              {
                (preview === "NO")?`[ Lvl.${selectedLevel} - ${gold} gold/hr ]: Better workers already in list`:((preview === "ADD")?`[ Lvl.${selectedLevel} - ${gold} gold/hr ]: Add to list`:"ㅤ")
              }
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox display="flex" alignItems="center" justifyContent="center">
            <Grid container spacing={1}>
              {
                (idle.roster.filter(obj => obj.job === selectedJob).length===0)?(
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100px"
                    border="1px dashed"
                    borderColor="#63656C"
                    borderRadius="8px"
                    width="100%"
                    mt={1}
                    ml={1}
                  >
                    <MDTypography variant="body2">
                      No idles
                    </MDTypography>
                  </MDBox>
                ):(
                  idle.roster.filter(obj => obj.job === selectedJob).map((item) => (
                    <Grid key={uuidv4()} item xs={4} xl={2}>
                      <IdleCard
                        item={item}
                        deleteCallback={handleDeleteIdle}
                        addCallback={()=>{handleAddToRoster(item)}}
                        updateCallback={handleUpdateIdle}
                        disableAddButton={jobStates[item.job].roster.length >= jobStates[item.job].slots}
                        swapCallback={(item.idleTag !== 'disregard' && item.idleTag !== 'NEW')?()=>{
                          handleSwap(item, jobStates[item.job].roster.find(obj => obj.id === item.idleTag))
                        }:null}
                        swapItem={(item.idleTag !== 'disregard' && item.idleTag !== 'NEW')?(jobStates[item.job].roster.find(obj => obj.id === item.idleTag) || {}):null}
                      />
                    </Grid>
                  )
                ))}
            </Grid>
          </MDBox>
        </Grid>
        {jobData.map(renderJobRoster)}
      </Grid>
    </PageLayout>
  );
}

export default Home;

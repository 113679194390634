import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import Card from "@mui/material/Card";
import { CancelOutlined, CheckOutlined, EditOutlined } from "@mui/icons-material";
import MDInput from "../../MDBase/MDInput";
import MDBadge from "../../MDBase/MDBadge";

const WorkerCard = ({ item, deleteCallback, updateCallback }) => {
  const [isDeleting, setDeleting] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [updatedItem, setUpdatedItem] = useState({ ...item });
  const [errors, setErrors] = useState({ level: false, price: false });

  const buttonStyle = (color) => ({
    color: "text.secondary",
    p: 0,
    "&:hover": {
      color: color,
    },
  });

  const validateInputs = () => {
    const newErrors = {
      level: !updatedItem.level || updatedItem.level <= 0 || updatedItem.level > 10,
      price: !updatedItem.price || updatedItem.price <= 0,
    };

    setErrors(newErrors);
    return !newErrors.level && !newErrors.price;
  };

  const handleDeleteButton = () => (
    <IconButton
      onClick={() => {
        if (isDeleting) {
          deleteCallback(item);
        } else {
          setDeleting(true);
          setEditing(false);
        }
      }}
      size="small"
      sx={buttonStyle("error.main")}
    >
      {isDeleting ? <CheckOutlined fontSize="small" /> : <DeleteOutlineIcon fontSize="small" />}
    </IconButton>
  );

  const handleEditButton = () => (
    <IconButton
      onClick={() => {
        if (isEditing) {
          if (validateInputs()) {
            updateCallback(updatedItem);
            setEditing(false);
          }
        } else {
          setEditing(true);
          setDeleting(false);
        }
      }}
      size="small"
      sx={isEditing ? buttonStyle("success.main") : buttonStyle("info.main")}
    >
      {isEditing ? <CheckOutlined fontSize="small" /> : <EditOutlined fontSize="small" />}
    </IconButton>
  );

  const handleCancelButton = (remove = false) => (
    <IconButton
      onClick={() => {
        if (remove) {
          setDeleting(false);
        } else {
          setUpdatedItem({ ...item });
          setEditing(false);
        }
      }}
      size="small"
      sx={buttonStyle("error.main")}
    >
      <CancelOutlined fontSize="small" />
    </IconButton>
  );

  const renderLevel = () => {
    let color = {
      color: "#FFFFFF", background: "rgba(0,0,0,0.25)"
    }

    if (item.level>9) {
      color = {
        color: "#423509", background: "#d4af37"
      }
    } else if (item.level>7) {
      color = {
        color: "#FFFFFF", background: "#736426"
      }
    } else if (item.level>4) {
      color = {
        color: "#FFFFFF", background: "#70674f"
      }
    } else if (item.level>1) {
      color = {
        color: "#FFFFFF", background: "#474646"
      }
    }
    return isEditing ? (
      <MDInput
        required
        variant="standard"
        type="number"
        defaultValue={item.level}
        fullWidth
        InputProps={{
          inputProps: { min: 1, max: 10 },
          startAdornment: (
            <MDTypography variant="caption" color="white" pr={1}>
              Lvl.
            </MDTypography>
          ),
        }}
        error={errors.level}
        helperText={errors.level ? "Level is required" : ""}
        onChange={(e) => setUpdatedItem({ ...updatedItem, level: parseInt(e.target.value)??parseInt(item.level.toString()) })}
      />
    ) : (
      <MDBadge
        variant="contained"
        size="md"
        badgeContent={`Lvl. ${item.level}`}
        sx={{ marginRight: "1rem", "& .MuiBadge-badge": color }}
        container
      />
    );
  };

  const renderWage = () => {
    return isEditing ? (
      <MDInput
        required
        variant="standard"
        type="number"
        defaultValue={item.price}
        fullWidth
        InputProps={{
          inputProps: { min: 1 },
          endAdornment: (
            <MDTypography variant="caption" color="white">
              /hr
            </MDTypography>
          ),
        }}
        error={errors.price}
        helperText={errors.price ? "Wage is required" : ""}
        onChange={(e) => setUpdatedItem({ ...updatedItem, price: parseInt(e.target.value)??parseInt(item.price.toString()) })}
      />
    ) : (
      <MDTypography
        variant="caption"
        color="white"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: 1,
        }}
      >
        {item.price} / hr
      </MDTypography>
    );
  };

  return (
    <Card>
      <MDBox
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          borderRadius: "4px",
          gap: "0.5rem",
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0.5}
          sx={{ gap: "0.5rem" }}
        >
          {isEditing ? (
            <MDInput
              variant="standard"
              defaultValue={item.note}
              fullWidth
              onChange={(e) => setUpdatedItem({ ...updatedItem, note: e.target.value })}
            />
          ) : (
            <MDTypography
              variant="body2"
              fontWeight="medium"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: 1,
              }}
            >
              {item.note}
            </MDTypography>
          )}
          {handleDeleteButton()}
          {isDeleting ? handleCancelButton(true) : null}
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0.5}
          sx={{ gap: "0.5rem" }}
        >
          {renderWage()}
          {renderLevel()}
          {handleEditButton()}
          {isEditing ? handleCancelButton() : null}
        </MDBox>
      </MDBox>
    </Card>
  );
};

WorkerCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
  }).isRequired,
  deleteCallback: PropTypes.func.isRequired,
  updateCallback: PropTypes.func.isRequired,
};

export default WorkerCard;

/**
 * types:
 * remove
 * add
 * swap
 *
 * remove should only have remove button
 * add should only have add button
 * swap should have swap button and add button
 *
 * removing and updating should call handleIdleMarking, swaps and adds dont since we have useeffect
 */
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "../../MDBase/MDBox";
import MDTypography from "../../MDBase/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../MDBase/MDButton";
import React, { useState } from "react";
import MDBadge from "../../MDBase/MDBadge";
import MDInput from "../../MDBase/MDInput";
import { CancelOutlined, CheckOutlined } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const IdleCard = ({item, addCallback, deleteCallback, swapCallback, updateCallback, swapItem, disableAddButton }) => {
  const [isDeleting, setDeleting] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isAdding, setAdding] = useState(false);
  const [isSwapping, setSwapping] = useState(false);

  const [isHovering, setHovering] = useState(false);

  const [updatedItem, setUpdatedItem] = useState({ ...item });
  const [errors, setErrors] = useState({ level: false, price: false });

  const validateInputs = () => {
    const newErrors = {
      level: !updatedItem.level || updatedItem.level <= 0 || updatedItem.level > 10,
      price: !updatedItem.price || updatedItem.price <= 0,
    };

    setErrors(newErrors);
    return !newErrors.level && !newErrors.price;
  };

  const renderNote = (swap=false) => {
    return isEditing && !swap ? (
      <MDInput
        variant="standard"
        defaultValue={item.note}
        fullWidth
        onChange={(e) => setUpdatedItem({ ...updatedItem, note: e.target.value })}
      />
    ) : (
      <MDTypography
        variant="body2"
        fontWeight="medium"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: 1,
        }}
      >
        {(swap)?swapItem.note:item.note}
      </MDTypography>
    )
  }

  const renderLevel = (swap=false) => {
    let color = {
      color: "#FFFFFF", background: "rgba(0,0,0,0.25)"
    }

    if (((swap)?swapItem.level:item.level)>9) {
      color = {
        color: "#423509", background: "#d4af37"
      }
    } else if (((swap)?swapItem.level:item.level)>7) {
      color = {
        color: "#FFFFFF", background: "#736426"
      }
    } else if (((swap)?swapItem.level:item.level)>4) {
      color = {
        color: "#FFFFFF", background: "#70674f"
      }
    } else if (((swap)?swapItem.level:item.level)>1) {
      color = {
        color: "#FFFFFF", background: "#474646"
      }
    }
    return isEditing && !swap ? (
      <MDInput
        required
        variant="standard"
        type="number"
        defaultValue={item.level}
        fullWidth
        InputProps={{
          inputProps: { min: 1, max: 10 },
          startAdornment: (
            <MDTypography variant="caption" color="white" pr={1}>
              Lvl.
            </MDTypography>
          ),
        }}
        error={errors.level}
        helperText={errors.level ? "Level is required" : ""}
        onChange={(e) => setUpdatedItem({ ...updatedItem, level: parseInt(e.target.value)??parseInt(item.level.toString()) })}
      />
    ) : (
      <MDBadge
        variant="contained"
        size="md"
        badgeContent={`Lvl. ${(swap)?swapItem.level:item.level}`}
        sx={{ marginRight: "1rem", "& .MuiBadge-badge": color }}
        container
      />
    );
  };

  const renderWage = (swap=false) => {
    return isEditing && !swap ? (
      <MDInput
        required
        variant="standard"
        type="number"
        defaultValue={item.price}
        fullWidth
        InputProps={{
          inputProps: { min: 1 },
          endAdornment: (
            <MDTypography variant="caption" color="white">
              /hr
            </MDTypography>
          ),
        }}
        error={errors.price}
        helperText={errors.price ? "Wage is required" : ""}
        onChange={(e) => setUpdatedItem({ ...updatedItem, price: parseInt(e.target.value)??parseInt(item.price.toString()) })}
      />
    ) : (
      <MDTypography
        variant="caption"
        color="white"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: 1,
        }}
      >
        {(swap)?swapItem.price:item.price} / hr
      </MDTypography>
    );
  };

  const renderEditButton = () => {
    return (
      <MDButton
        variant="outlined"
        color={(isEditing || isAdding || isSwapping)?"error":"info"}
        size="small"
        fullWidth
        onClick={()=>{
          if (isEditing || isAdding || isSwapping) {
            setEditing(false);
            setAdding(false);
            setSwapping(false);
          } else {
            setEditing(true);
          }
        }}
      >
        {(isEditing || isAdding || isSwapping)?"Cancel":"Edit"}
      </MDButton>
    );
  }

  const renderAddButton = () => {
    let text = "Add";

    if (isEditing) { text = "Confirm" }
    else if (isSwapping) { text = "Swap" }
    else if (isAdding) { text = "Add" }
    return (
      <MDButton
        disabled={(!isEditing && !isAdding && !isSwapping) && disableAddButton}
        variant="outlined"
        color={(isEditing || isAdding || isSwapping)?"success":getAddButtonColor()}
        size="small"
        fullWidth
        onClick={()=>{
          if (isEditing) {
            if (validateInputs()) {
              updateCallback(updatedItem);
              setEditing(false);
            }
          } else if (isSwapping) {
            swapCallback();
          } else if (isAdding) {
            addCallback();
          } else {
            setAdding(true)
          }
        }}
      >
        {text}
      </MDButton>
    );
  }

  const renderSwapButton = () => {
    return (swapItem && (!isEditing && !isAdding && !isSwapping))?(
      <MDButton
        variant="outlined"
        color="success"
        size="small"
        fullWidth
        onClick={()=>{
          setSwapping(true);
        }}
      >
        swap
      </MDButton>
    ):null
  }

  const buttonStyle = (color) => ({
    color: "text.secondary",
    p: 0,
    "&:hover": {
      color: color,
    },
  });

  const renderDeleteButton = () => {
    return (
      <Card sx={{
        position: "absolute",
        top: -20,
        right: -10,
        zIndex: 1,
        padding: "0.5rem",
      }}>
        <MDBox display="flex" sx={{gap:"0.5rem"}}>
          <IconButton
            onClick={() => {
              if (isDeleting) {
                deleteCallback(item);
              } else {
                setDeleting(true);
              }
            }}
            size="small"
            sx={buttonStyle("error.main")}
          >
            <Tooltip title={isDeleting?"Confirm Delete":"Delete"}>
              {(isDeleting)?<CheckOutlined fontSize="small" />:<DeleteOutlineIcon fontSize="small" />}
            </Tooltip>
          </IconButton>
          {
            (isDeleting)&&(
              <IconButton
                onClick={() => {
                  setDeleting(false);
                }}
                size="small"
                sx={buttonStyle("error.main")}
              >
                <Tooltip title="Cancel">
                  <CancelOutlined fontSize="small" />
                </Tooltip>
              </IconButton>
            )

          }
        </MDBox>
      </Card>
    )
  }

  const getAddButtonColor = () => {
    if (item.idleTag === 'NEW') {return "success"}
    else if (item.idleTag === "disregard") {return "error"}
    else {return "warning"}
  }

  return (
    <MDBox
      sx={{position:"relative"}}
      onMouseEnter={()=>{setHovering(true)}}
      onMouseLeave={()=>{
        setDeleting(false)
        setHovering(false)
      }}
    >
      {isHovering && (
        renderDeleteButton()
      )}
      <Card sx={{zIndex:0}}>
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
            gap: "0.5rem",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={(swapItem)?6:12} >
              <MDBox>
                <Grid container>
                  <Grid item xs={12}>
                    {renderNote()}
                  </Grid>
                  <Grid item xs={12}>
                    {renderLevel()}
                  </Grid>
                  <Grid item xs={12}>
                    {renderWage()}
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>

            {
              (swapItem)?(
                <Grid item xs={6} sx={{ backgroundColor:"rgba(206,95,95,0.3)",
                  borderRadius: "0 8px 0 0", paddingLeft:1, opacity:"0.75"}}>
                  <MDBox>
                    <Grid container>
                      <Grid item xs={12}>
                        {renderNote(true)}
                      </Grid>
                      <Grid item xs={12}>
                        {renderLevel(true)}
                      </Grid>
                      <Grid item xs={12}>
                        {renderWage(true)}
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              ):null
            }
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {renderEditButton()}
            </Grid>
            <Grid item xs={4}>
              {renderSwapButton()}
            </Grid>
            <Grid item xs={4}>
              {renderAddButton()}
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  )
}

IdleCard.defaultProps = {
  swapCallback: null,
  swapItem: null,
  disableAddButton: false
}

IdleCard.propTypes = {
  item: PropTypes.object.isRequired,
  addCallback: PropTypes.func.isRequired,
  deleteCallback: PropTypes.func.isRequired,
  updateCallback: PropTypes.func.isRequired,
  swapCallback: PropTypes.func,
  swapItem: PropTypes.object,
  disableAddButton: PropTypes.bool
}

export default IdleCard;
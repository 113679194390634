// indexedDBService.js
import { openDB } from 'idb';

const DB_NAME = 'my-database';
const DB_VERSION = 1;
const STORE_NAME = 'my-store';

export async function initializeDB() {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    },
  });
}

export async function addItem(item) {
  const db = await initializeDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.add(item);
}

export async function getAllItems() {
  const db = await initializeDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  return store.getAll();
}

export async function addOrReplaceItem(id, item) {
  const db = await initializeDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);

  const existingItem = await store.get(id);
  if (existingItem) {
    // If item exists, update it
    await store.put({ ...item, id});
  } else {
    // If item doesn't exist, add it
    await store.add({ ...item, id});
  }
}

export async function clearDatabase() {
  const db = await initializeDB();
  const tx = db.transaction(db.objectStoreNames, 'readwrite');

  // Iterate over all object stores and clear them
  Array.from(tx.objectStoreNames).forEach((storeName) => {
    tx.objectStore(storeName).clear();
  });

  // Complete the transaction
  await tx.done;
}
// Add other CRUD operations as needed
